<template>
    <div class="connect-login-view">
        <n-spin :size="50"></n-spin>
        <div class="text">获取MaxConnect用户信息中...</div>
    </div>
</template>

<style lang="less" scoped>
@import '../../common/common.less';

.connect-login-view {
    .common-content;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .text {
        margin-left: 20px;
        font-size: 20px;
        font-weight: bold;
    }
}
</style>

<script setup>
import { onMounted } from 'vue';

import { useRoute, useRouter } from 'vue-router';

import { NSpin, useMessage } from 'naive-ui';

import { isEmptyArray } from '@/common/tools';

import { userConnectLogin } from '@/common/API';

import { useUserStore } from '@/stores/global';

const store = useUserStore();

const route = useRoute();

const router = useRouter();

const message = useMessage();

onMounted(() => {
    let code = route.query.code;
    let state = route.query.state;
    userConnectLogin(code).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        store.setCurrentUser({
            username: res.data.userName,
            roles: isEmptyArray(res.data.role_list)
                ? ['guest']
                : res.data.role_list,
        });
        router.replace(state);
    });
});
</script>
